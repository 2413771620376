import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { render } from 'react-dom'
import Redbox from 'redbox-react'
import { EnvironmentConfig, loadEnvironmentConfig } from './config/environment'
import { renderLoading } from './utils/loading'

const initSentry = (envConfig: EnvironmentConfig) => {
  const isLocal = envConfig.envName === 'Local'
  const SAMPLE_RATE = 1.0 // https://forum.sentry.io/t/clarification-needed-for-sample-rate-configuration/8515
  Sentry.init({
    dsn: 'https://0300caf559014a06a92a00c85381ee54@o182716.ingest.sentry.io/5201946',
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: envConfig.envName !== 'Local' })],
    enabled: !isLocal,
    debug: isLocal,
    sampleRate: isLocal ? 1 : SAMPLE_RATE,
  })
}

renderLoading('loading')
loadEnvironmentConfig()
  .then((envConfig) => {
    initSentry(envConfig)
  })
  .then(() => {
    renderLoading('Ready')
    import('./App').then(({ App }) => {
      render(<App />, document.getElementById('root'))
    })
  })

  .catch((err) => {
    render(<Redbox error={err} />, document.getElementById('root'))
  })
